import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AvatarComponent} from "../../avatar/AvatarComponent";
import {
    ButtonSwitchComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/check/switch/ButtonSwitchComponent";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {IAccountModel} from "../../../../../../models/account/IAccountModel";
import {AccountSessionService} from "../../../../../../services/account/session/AccountSessionService";
import * as s from "./account-board.scss";
import {AccountService} from "../../../../../../services/account/AccountService";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {Network} from "../../../../../../network/Network";
import {config} from "../../../../../../config";
import {Resources} from "../../../../../../resources/Resources";
import {PermissionsService} from "../../../../../../services/permissions/PermissionsService";
import {ProductType} from "../../../../../../models/product/ProductType";
import {
    LanguagePanelPickerComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/language/panel/LanguagePanelPickerComponent";
import {SiteService} from "../../../../../../services/site/SiteService";
import {SitesModalComponent} from "../../site/modal/SitesModalComponent";

export class AccountBoardComponent extends Component {

    public account: IAccountModel;

    public activityButtonSwitch: ButtonSwitchComponent;
    public siteButton: Component;
    public languagePicker: LanguagePanelPickerComponent;

    constructor() {
        super();
        this.account = EntityService.activeEntity as IAccountModel;

        this.activityButtonSwitch = new ButtonSwitchComponent(this.account.active);
        this.languagePicker = new LanguagePanelPickerComponent(Resources.language);

        //language=HTML
        this.template = `
            <div class="${s.componentAccountBoard}">
                <div style="${PermissionsService.d(PermissionsService.canProduct(ProductType.INBOX))}"
                     class="${s.head}">
                    <div class="${s.headTypes}">
                        <a href="/general/account">
                            <div class="${s.account}">
                                <div class="${s.leftSide}">
                                    <div class="${s.avatarContainer}">
                                        ${this.draw(new AvatarComponent(this.account.avatar).setActive(this.account))}
                                    </div>
                                    <div class="${s.name}">${this.reactive(() => this.account.name)}</div>
                                </div>
                            </div>
                        </a>
                        <div class="${s.activity}">
                            <div class="${s.text}">${Resources.t("words.active")}</div>
                            <div class="${s.switchContainer}">
                                ${this.draw(this.activityButtonSwitch)}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="${s.buttons}">
                    <div data-path="account" class="${s.button} ${s.account}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.myAccount")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.editMyAccount")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div data-path="subscriptions" class="${s.button} ${s.subscription}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.subscriptions")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.manageMySubscriptions")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.separator}"></div>
                    <div data-path="members" class="${s.button} ${s.members}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.collaborators")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.manageCollaborators")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div data-path="roles" class="${s.button} ${s.roles}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.rolesName")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.permissions")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div data-path="invitations" class="${s.button} ${s.link}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.invitations")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.inviteCollaborators")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.separator}"></div>
                    <div data-path="workspace" class="${s.button} ${s.workspace}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.workspace")}
                            </div>
                            <div class="${s.description}">
                                ${Resources.t("words.editMyWorkspace")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.button} ${s.site}"></div>
                    <div class="${s.separator}"></div>
                    <div class="${s.button} ${s.logout}">
                        <div class="${s.iconContainer}">
                            <div class="${s.icon}"></div>
                        </div>
                        <div class="${s.texts}">
                            <div class="${s.text}">
                                ${Resources.t("words.deconnexion")}
                            </div>
                        </div>
                        <div class="${s.arrow}"></div>
                    </div>
                    <div class="${s.language}">
                        <div>
                            ${Resources.t("words.language")}
                        </div>
                        <div class="${s.picker}">
                            ${this.draw(this.languagePicker)}
                        </div>
                    </div>
                </div>
                <div class="${s.build}">
                    ${config.version}
                    ·
                    <a href="${Network.router.pages.getWebsiteUrl('cgu')}"
                       target="_blank">${Resources.t("words.account.copyright.cgu")}</a> ·
                    <a href="${Network.router.pages.getWebsiteUrl('cgv')}"
                       target="_blank">${Resources.t("words.account.copyright.cgv")}</a> ·
                    <a href="${Network.router.pages.getWebsiteUrl('policy')}"
                       target="_blank">${Resources.t("words.account.copyright.policy")}</a>
                </div>
                <div style="height: 15px;"></div>
            </div>
        `;
    }

    commit() {
        super.commit();
        super.scrollable();

        this.renderSiteButton();
        this.renderLogoutButton();
        this.renderActivitySwitch();

        this.languagePicker.onSelect = language => Network.router.pages.setLanguage(language);
        this.els(s.button).forEach(value => value.onClick(() => this.onPick(value.getAttribute("data-path"))));
    }

    renderActivitySwitch() {
        this.activityButtonSwitch.onClick(async () => {
            this.activityButtonSwitch.setLoading();
            await AccountService.activity(this.activityButtonSwitch.checked, this);
            this.activityButtonSwitch.removeLoading();
        });
    }

    renderLogoutButton() {
        let logoutButton = this.el(s.logout);
        let onLogout = false;
        logoutButton.onClick(async (e) => {
            e.stopPropagation();
            e.stopImmediatePropagation();
            if (!onLogout) {
                onLogout = true;
                logoutButton.save();
                logoutButton.clear();
                logoutButton.render(new LoaderLightComponent());
                await AccountSessionService.logout(this);
            }
        }, true);
    }

    renderSiteButton() {
        this.siteButton = this.el(s.site);

        if (EntityService.activeSite == undefined) {
            //language=HTML
            this.siteButton.append(`
                <span>${Resources.t("words.account.selectedWorkspace")}</span>
            `);
        } else {
            //language=HTML
            this.siteButton.append(`
                <div class="${s.iconContainer}">
                    <div class="${s.avatar}">
                        ${this.siteButton.draw(new AvatarComponent(EntityService.activeSite.avatar))}
                    </div>
                </div>
            `);
            //language=HTML
            this.siteButton.append(`
                <div class="${s.texts}">
                    <div class="${s.text}">
                        ${this.siteButton.reactive(() => EntityService.activeSite.name)}
                    </div>
                    <div class="${s.description}">
                        ${Resources.t("words.changeWorkspace")}
                    </div>
                </div>
            `);
        }

        this.siteButton.onClick(async () => {
            this.siteButton.save();
            this.siteButton.clear();
            this.siteButton.render(new LoaderLightComponent());

            let sites = await SiteService.findMind(this);
            this.siteButton.restore();

            let modal = new SitesModalComponent(sites);
            modal.sitesComponent.onPick = (site) => {
                modal.close();
                this.siteButton.clear();
                this.siteButton.render(new LoaderLightComponent());
                SiteService.select(site.id, this);
            };
            modal.screenRender();
            this.onDispose();
        }, true);
    }

    onPick(path: string) {

    }

    onDispose() {

    }
}
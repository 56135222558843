import {MachineListener} from "../../machine/listener/MachineListener";
import {SedestralMachine} from "../../machine/SedestralMachine";
import {SedestralInterface} from "../SedestralInterface";
import {ComponentScrollbar} from "./scrollbar/ComponentScrollbar";
import {IComponentTranslate} from "./types/IComponentTranslate";
// @ts-ignore
import {observable, observe, unobserve} from "@nx-js/observer-util";
import {IOffsets} from "../../utilities/types/IOffsets";
import {ComponentTooltip} from "./tooltip/ComponentTooltip";
import {ComponentSwapBar} from "./swapbar/ComponentSwapBar";
import {SedestralDebugger} from "../../debugger/SedestralDebugger";
import {INetworkComponentError} from "../../../../network/types/INetworkComponentError";
import {elementChildrens} from "../../utilities/ElementChildrens";
import {elementNotChildren} from "../../utilities/ElementNotChildren";
import {elementHasClass} from "../../utilities/ElementHasClass";
import {elementOffsets} from "../../utilities/ElementOffsets";
import {elementParents} from "../../utilities/ElementParents";
import {filterXSS} from "../../utilities/FilterXSS";
import {randomString} from "../../utilities/RandomString";
import {randomUUID} from "../../utilities/RandomUUID";
import {isMobile} from "../../utilities/IsMobile";

//TODO individualiser en export chaque fonction
export class Component {

    public element: Node;
    public componentId: string;

    public template: string;
    public type: string;

    public timeOuts: number[];
    public intervals: number[];

    public reactives: (() => void)[];
    public listeners: MachineListener[];
    public hotKeyListeners: string[];
    public childrens: Component[];
    public isHide: boolean;

    public savedStyle: string;
    public savedHtml: string;
    public savedScroll: number;
    public savedScrollCalc: number;


    public placeHolderSelector: string
    public placeholderStyleElement: HTMLElement;

    public committed: boolean;
    public committedError: boolean;
    public drawCommitted: boolean;

    public scrollbar: ComponentScrollbar;
    public swapBar: ComponentSwapBar;

    public tooltip: ComponentTooltip;
    public tooltipShowTimeout: number;
    public tooltipShowShadowTimeout: number;
    public tooltipListeners: MachineListener[];

    public outsideClickExcludedComponents: Component[];

    public activeKeys: any;
    public activeKeysCaps: boolean;

    public mutationObserver: MutationObserver;
    public mutationFunctions: ((mutations?: MutationRecord[]) => void)[];

    public onCommitFunctions: (() => void)[];
    public onRemoveFunctions: (() => void)[];

    public networkErrorsHandler: INetworkComponentError[] = [];

    public tagParents: object = {"th": "tr", "td": "tr", "tr": "tbody"};

    public constructor(element?: Node) {
        this.element = element;
        this.componentId = randomUUID();

        this.timeOuts = [];
        this.intervals = [];

        this.reactives = [];
        this.listeners = [];
        this.hotKeyListeners = [];
        this.childrens = [];
        this.isHide = false;

        this.savedHtml = undefined;
        this.savedStyle = undefined;
        this.savedScroll = 0;
        this.savedScrollCalc = 0;

        this.template = `<div></div>`;
        this.type = "DIV";

        this.committedError = false;
        this.committed = false;
        this.drawCommitted = true;


        this.onCommitFunctions = [];
        this.onRemoveFunctions = [];
        this.tooltipListeners = [];

        this.outsideClickExcludedComponents = [];

        SedestralInterface.store(this);
    };

    /**
     * Logic
     */
    public property(type: string): number {
        let offsets = this.getParentOffsets();
        let offsetProperty = typeof offsets[type] == "undefined" ? 0 : offsets[type];
        if (type == "height") {
            offsetProperty = this.getHeight();
        }

        return offsetProperty;
    }

    /**
     * Machine
     */
    public async sleep(ms: number): Promise<void> {
        await this.promise((resolve) => {
            this.timeOut(() => {
                resolve(undefined);
            }, ms);
        })
    }

    public runAsCommit(func: () => void) {
        if (this.committed) {
            func();
        } else {
            this.onCommit(() => func());
        }
    }

    public async wait(animations: any[]) {
        await Promise.all(animations);
    }

    public async promise<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, displayRequired?: boolean): Promise<T> {
        return SedestralMachine.promise(executor, displayRequired, this);
    }

    public async simplePromise<T>(executor: (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, displayRequired?: boolean): Promise<T> {
        return new Promise(executor);
    }

    /**
     * Animations
     */

    public async translate(params: IComponentTranslate): Promise<void> {
        return this.simplePromise((resolve) => {
            if (this.element instanceof HTMLElement) {
                let delay = params.delay == undefined ? 100 : params.delay;
                let delayStyle = `transition-duration: ${delay / 1000}s;`;

                /**
                 * transform
                 */
                let transformStyle = ``;
                if (params.translateX != undefined) {
                    transformStyle += `translateX(${params.translateX}px) `;
                }

                if (params.translateY != undefined) {
                    transformStyle += `translateY(${params.translateY}px) `;
                }

                if (params.scale != undefined) {
                    transformStyle += `scale(${params.scale}) `;
                }

                if (params.rotate != undefined) {
                    transformStyle += `rotate(${params.rotate}) `;
                }

                if (transformStyle != ``) {
                    transformStyle = `transform:${transformStyle};`;
                }
                /**
                 * position
                 */
                let offsets = this.getParentOffsets();
                if (offsets == undefined) {
                    return;
                }

                let positionStyle = ``;
                if (params.left != undefined) {
                    this.element.style.left = `${offsets['left']}px`;
                    this.element.style.right = "unset";
                    positionStyle += `left:${offsets['left'] + params.left}px;`;
                }

                if (params.top != undefined) {
                    this.element.style.top = `${offsets['top']}px`;
                    this.element.style.bottom = "unset";
                    positionStyle += `top:${offsets['top'] + params.top}px;`;
                }

                if (params.bottom != undefined) {
                    this.element.style.bottom = `${offsets['bottom']}px`;
                    this.element.style.top = "unset";
                    positionStyle += `bottom:${offsets['bottom'] + params.bottom}px;`;
                }

                if (params.right != undefined) {
                    this.element.style.right = `${offsets['right']}px`;
                    this.element.style.left = "unset";
                    positionStyle += `right:${offsets['right'] + params.right}px;`;
                }

                /**
                 * move
                 */
                if (params.moveRight != undefined) {
                    this.element.style.right = `${offsets['right']}px`;
                    this.element.style.left = "unset";
                    positionStyle += `right:${params.moveRight}px;`;
                }
                if (params.moveLeft != undefined) {
                    this.element.style.left = `${offsets['left']}px`;
                    this.element.style.right = "unset";
                    positionStyle += `left:${params.moveLeft}px;`;
                }
                if (params.moveTop != undefined) {
                    this.element.style.top = `${offsets['top']}px`;
                    this.element.style.bottom = "unset";
                    positionStyle += `top:${params.moveTop}px;`;
                }
                if (params.moveBottom != undefined) {
                    this.element.style.bottom = `${offsets['bottom']}px`;
                    this.element.style.top = "unset";
                    positionStyle += `bottom:${params.moveBottom}px;`;
                }

                let propertiesStyle = ``;
                /**
                 * properties
                 */
                if (params.opacity != undefined) {
                    propertiesStyle += `opacity:${params.opacity};`;
                }

                if (params.padding != undefined) {
                    propertiesStyle += `padding:${params.padding}px;`;
                }

                if (params.margin != undefined) {
                    propertiesStyle += `margin:${params.margin}px;`;
                }

                if (params.height != undefined) {
                    this.element.style.height = `${this.getHeight()}px`;
                    propertiesStyle += `height:${params.height}px;`;
                }

                if (params.maxHeight != undefined) {
                    this.element.style.maxHeight = `${this.getHeight()}px`;
                    propertiesStyle += `max-height:${params.maxHeight}px;`;
                }

                if (params.maxWidth != undefined) {
                    this.element.style.maxWidth = `${this.getWidth()}px`;
                    propertiesStyle += `max-width:${params.maxWidth}px;`;
                }

                if (params.width != undefined) {
                    this.element.style.width = `${this.getWidth()}px`;
                    propertiesStyle += `width:${params.width}px;`;
                }


                if (params.borderRadius != undefined) {
                    propertiesStyle += `border-radius:${params.borderRadius};`;
                }
                /**
                 * execution
                 */
                SedestralMachine.requestFrame()(() => {
                    setTimeout(() => {
                        this.setStyle(transformStyle + delayStyle + positionStyle + propertiesStyle);
                    });
                });

                /**
                 * sync
                 */
                this.timeOut(() => {
                    resolve();

                    if (params.remove != undefined && params.remove == true) {
                        this.remove();
                    }

                }, delay);
            }
        });
    }

    public async scale(start, value, speed): Promise<void> {
        if (this.element instanceof HTMLElement) {
            this.element.style.transform = "scale(" + start + ")";
            await this.translate({delay: speed, scale: value});
        }
    };

    public async fade(value: "in" | "out", speed): Promise<void> {
        if (this.element instanceof HTMLElement) {
            if (value == "in") {
                this.show();
            }

            let start = value == "in" ? 0 : 1;
            let final = value == "in" ? 1 : 0;

            this.setStyle(`opacity:${start}`);
            await this.translate({delay: speed, opacity: final});
        }
    };

    /**
     * toggle
     */
    public toggle(tog: () => void, gle: () => void) {
        let toggled = false;

        this.onClick(() => {
            if (!toggled) {
                tog();
                toggled = true;
            } else {
                gle();
                toggled = false;
            }

        });

    }

    /**
     * Animations advanced
     */

    public async openUp(delay?: number) {
        if (!isMobile()) {
            return this.scaleShow(delay);
        } else {
            return this.swapUp();
        }
    }

    public async closeDown(delay?: number, scale?: number, clear?: boolean) {
        if (!isMobile()) {
            return this.scaleHide(delay, scale, clear);
        } else {
            return this.swapDown(clear);
        }
    }

    public async swapUp() {
        this.displayShow();
        this.setStyle(`transform:translateY(100%) scale(1);opacity:1;`);
        await this.translate({translateY: 0, delay: 400});
    }

    public async swapDown(clear?: boolean) {
        this.setStyle(`transform:scale(1);opacity:1;`);
        await this.translate({translateY: window.innerHeight, delay: 400});
        this.displayHide();
        if (clear == undefined || clear == true) {
            this.clearAll();
        }
    }

    public async scaleShow(delay?: number, withoutDisplay?: boolean): Promise<void> {
        if (!withoutDisplay) {
            this.displayShow();
        }
        this.setStyle(`transform:scale(0.9);opacity:0;`);
        await this.translate({delay: delay == undefined ? 80 : delay, scale: 1, opacity: 1});
        this.isHide = false;
    }

    public async scaleHide(delay?: number, scale?: number, clear?: boolean): Promise<void> {
        this.setStyle(`transform:scale(1);opacity:1;`);
        await this.simplePromise((resolve) => {
            SedestralMachine.requestFrame()(async () => {
                await this.translate({
                    delay: delay == undefined ? 80 : delay,
                    scale: scale == undefined ? 0.9 : scale,
                    opacity: 0
                });
                this.displayHide();

                SedestralMachine.requestFrame()(async () => {
                    this.timeOut(() => {
                        if (clear == undefined || clear == true) {
                            this.clearAll();
                        }
                        resolve(true);
                    }, 100);
                });
            });
        });
        this.isHide = true;
    }

    public async scaleShowTranslate(delay?: number): Promise<void> {
        this.show();
        this.setStyle(`transform:scale(0.9) translateY(20px);opacity:0;`);
        await this.translate({
            delay: delay == undefined ? 80 : delay,
            translateY: 0,
            scale: 1,
            opacity: 1
        });
    }

    /**
     * outside click
     */

    excludeOutsideClickComponent(component: Component) {
        this.outsideClickExcludedComponents.push(component);
        component.onRemove(() => this.outsideClickExcludedComponents.splice(this.outsideClickExcludedComponents.indexOf(component), 1));
    }

    /**
     * Scroll
     */

    public getScrollPercent() {
        if (this.element instanceof HTMLElement) {
            return Math.round((this.element.scrollTop / (this.element.scrollHeight - this.element.clientHeight)) * 100);
        }

        return 0;
    }


    public getScrollTop(): number {
        if (this.element instanceof HTMLElement) {
            return this.element.scrollTop;
        }
        return 0;
    }

    public getScrollLeft(): number {
        if (this.element instanceof HTMLElement) {
            return this.element.scrollLeft;
        }
        return 0;
    }

    public setScrollLeft(value: number): void {
        if (this.element instanceof HTMLElement) {
            this.element.scrollLeft = value;
        }
    }

    public setScrollTop(value: number): void {
        if (this.element instanceof HTMLElement) {
            this.element.scrollTop = value;
        }
    }

    public getScrollBottom(): number {
        if (this.element instanceof HTMLElement) {
            return (this.element.scrollHeight - Math.round(this.element.scrollTop + this.getHeight()));
        }
    }

    public scrollTop(value: number, speed: number): void {
        let easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) {
                return c / 2 * t * t + b;
            }
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };
        let start = this.getScrollTop(),
            change = value - start,
            currentTime = 0,
            increment = 20;
        let animateScroll = () => {
            currentTime += increment;
            this.setScrollTop(easeInOutQuad(currentTime, start, change, speed));
            if (currentTime < speed) {
                SedestralMachine.setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    public scrollIntoView(parent?: Component | HTMLElement, offset?: number, behavior?: ScrollBehavior): void {
        parent = parent == undefined ? this.element.parentElement : parent;

        if (parent instanceof HTMLElement) {
            let parentRect = parent.getBoundingClientRect();
            let childRect = this.getHTMLElement().getBoundingClientRect();
            parent.scrollTop = ((childRect.top + parent.scrollTop) - parentRect.top) + (offset == undefined ? 0 : offset);
        } else {
            let parentRect = parent.getHTMLElement().getBoundingClientRect();
            let childRect = this.getHTMLElement().getBoundingClientRect();
            let scrollTop = Math.round(((childRect.top + parent.getHTMLElement().scrollTop) - parentRect.top)) + (offset == undefined ? 0 : offset);
            parent.getHTMLElement().scrollTo({top: scrollTop, behavior: behavior ? behavior : "smooth"});
        }
    }

    public scrollBottom(value: number, speed): void {
        if (this.element instanceof HTMLElement) {
            let startDistance = this.element.scrollTop;
            let endDistance = (this.element.scrollHeight - this.getHeight()) - value;
            let frame = 0;
            let delta = (endDistance - startDistance) / speed / 0.06;
            let handle = SedestralMachine.setInterval(() => {
                if (this.element instanceof HTMLElement) {
                    frame++;
                    let value = startDistance + delta * frame;
                    this.element.scrollTo(0, value);
                    if (this.element == undefined) {
                        clearInterval(handle);
                    }

                    if (value >= endDistance) {
                        clearInterval(handle);
                    }
                }
            }, 1 / 0.06);
        }
    };

    public forceScrollUpdate(): void {
        this.addClass("scroll-sdt-scr");
        SedestralMachine.requestFrame()(() => this.removeClass("scroll-sdt-scr"));
    }

    /**
     * Dimensions
     */
    public getPaddingHeight(): number {
        let top = this.getHTMLElement().style.paddingTop.length > 0 ? parseInt(this.getHTMLElement().style.paddingTop) : 0;
        let bottom = this.getHTMLElement().style.paddingBottom.length > 0 ? parseInt(this.getHTMLElement().style.paddingBottom) : 0;
        return top + bottom;
    }

    public getFullHeight(): number {
        let height = this.getHTMLElement().clientHeight;
        let style = getComputedStyle(this.getHTMLElement());

        height += parseInt(style.paddingTop) + parseInt(style.paddingBottom);
        height += parseInt(style.marginTop) + parseInt(style.marginBottom);

        return height;
    }

    public getHeightCalculated() {
        let getFullHeight = (element) => {
            let style = window.getComputedStyle(element);
            let hauteur = element.offsetHeight;

            let margeHaut = parseInt(style.marginTop);
            let margeBas = parseInt(style.marginBottom);

            return hauteur + margeHaut + margeBas;
        }

        let height = 0;
        let childs = this.getHTMLElement() ? this.getHTMLElement().children : [];

        let parentStyle = window.getComputedStyle(this.getHTMLElement());
        let gap = parseInt(parentStyle.gap) || 0;

        for (let i = 0; i < childs.length; i++) {
            if (!childs[i].hasAttribute('scrolltrack')) {
                height += getFullHeight(childs[i]);
            }
        }

        height += gap * (childs.length - 1);

        return height;
    }


    public getHeight(): number {
        if (this.element instanceof HTMLElement) {
            return this.getHTMLElement().getBoundingClientRect().height + this.getPaddingHeight();
        }
    };

    public getHeightWithoutBorders(): number {
        return this.getHeight() - (this.getHTMLElement().offsetHeight - this.getHTMLElement().clientHeight);
    }

    public getWidth(): number {
        if (this.element instanceof HTMLElement) {
            return this.element.clientWidth;
        }
    }

    public getPaddingWidth(): number {
        let left = parseInt(this.getHTMLElement().style.paddingLeft);
        let right = parseInt(this.getHTMLElement().style.paddingRight);

        return (Number.isNaN(left) ? 0 : left) + (Number.isNaN(right) ? 0 : right);
    }

    public getMarginWidth(): number {
        let left = parseInt(this.getHTMLElement().style.marginLeft);
        let right = parseInt(this.getHTMLElement().style.marginRight);

        return (Number.isNaN(left) ? 0 : left) + (Number.isNaN(right) ? 0 : right);
    }

    /**
     * Offsets
     */

    public getParentOffsets(element?: Component): IOffsets {
        let parent: Node = this.element.parentNode;

        if (typeof element != "undefined") {
            parent = element.element;
        }

        if (parent == undefined) {
            return undefined;
        }


        let parentOffsets = elementOffsets(parent);
        let eOffsets = elementOffsets(this.element);
        return {
            top: eOffsets["top"] - parentOffsets["top"],
            left: eOffsets["left"] - parentOffsets["left"],
            right: eOffsets["right"] - parentOffsets["right"],
            bottom: eOffsets["bottom"] - parentOffsets["bottom"]
        };
    }

    public getOffsets(): IOffsets {
        return elementOffsets(this.element);
    };

    /**
     * Value and html and text
     */
    public getValue(): string {
        if (this.element instanceof HTMLInputElement || this.element instanceof HTMLTextAreaElement) {
            return this.element.value;
        }
        return "";
    }

    public getValueTrim(): string {
        return this.getValue().trim();
    }

    public setValue(value: string): string {
        if (this.element instanceof HTMLInputElement || this.element instanceof HTMLTextAreaElement) {
            this.element.value = value;
        }
        return "";
    }

    public streamValue(text: string, delay: number): void {
        let index = 0;
        this.setValue("");
        let interval = this.interval(() => {
            if (index < text.length) {
                this.setValue(this.getValue() + text[index]);
                index++;
            } else {
                clearInterval(interval);
            }
        }, delay / text.length);
    }

    public getHtml(): string {
        if (this.element instanceof HTMLElement) {
            return this.element.innerHTML;
        }
    };

    public setHtml(html: string | number): void {
        if (this.element instanceof HTMLElement) {
            this.element.innerHTML = "" + html;
        }
    };

    public getText(): string {
        if (this.element instanceof HTMLElement) {
            return this.element.innerText;
        }
    };

    public streamText(text: string, delay: number): void {
        let index = 0;
        this.setHtml("");  // Effacer le contenu initial

        const addHtml = () => {
            if (index < text.length) {
                if (text.substring(index, index + 8) === '<strong>') {
                    // On a trouvé le début d'une balise <strong>
                    const endOfStrongTag = text.indexOf('</strong>', index);
                    if (endOfStrongTag !== -1) {
                        // Ajouter la balise <strong>...</strong> entière en une fois
                        this.setHtml(this.getHtml() + text.substring(index, endOfStrongTag + 9));
                        index = endOfStrongTag + 9;  // Avancer l'index après la balise </strong>
                    }
                } else {
                    // Ajouter un caractère normal
                    this.setHtml(this.getHtml() + text[index]);
                    index++;
                }
            } else {
                clearInterval(interval);  // Arrêter l'insertion lorsque tout le texte est ajouté
            }
        };

        let interval = setInterval(addHtml, delay / text.length);
    }

    public setText(text: string): void {
        if (this.element instanceof HTMLElement) {
            this.element.innerText = text;
        }
    }

    /**
     * style
     */

    public setPlaceHolderStyle(value: string): void {
        this.placeHolderSelector = randomString(20, false);
        this.addClass(this.placeHolderSelector);

        if (!this.placeholderStyleElement) {
            this.placeholderStyleElement = document.createElement("style");
            this.placeholderStyleElement = this.getHTMLElement().appendChild(this.placeholderStyleElement);
        }


        this.placeholderStyleElement.innerHTML = `.${this.placeHolderSelector}::placeholder { ${value} }`;
    }

    public setStyle(value: string): void {
        if (this.element instanceof HTMLElement || this.element instanceof SVGElement) {
            this.element.style.cssText += value;
        }
    };

    public getStyle(strCssRule): string {
        let strValue = "";
        if (this.element instanceof HTMLElement) {
            if (document.defaultView && document.defaultView.getComputedStyle) {
                strValue = document.defaultView.getComputedStyle(this.element, "").getPropertyValue(strCssRule);
            } else if (this.element['currentStyle']) {
                strCssRule = strCssRule.replace(/\-(\w)/g, function (strMatch, p1) {
                    return p1.toUpperCase();
                });
                strValue = this.element['currentStyle'][strCssRule];
            }
        }

        return strValue;
    }

    public setStyleValue(type: string, value: string): void {
        if (this.element instanceof HTMLElement) {
            this.element.style[type] = value;
        }

    }

    /**
     * visibility status
     */

    public hide(): void {
        this.isHide = true;
        this.setStyle("visibility:hidden;");
    };

    public show(): void {
        this.isHide = false;
        this.setStyle("visibility:visible;");
    };

    public displayHide(): void {
        this.isHide = true;
        this.setStyle("display:none;");
    }

    public displayShow(): void {
        this.isHide = false;
        this.setStyle("display:block;");
    };

    public displayFlex(): void {
        this.isHide = false;
        this.setStyle("display:flex;");
    };

    public isDisplayNone(): boolean {
        return this.getStyle("display") == "none";
    }

    public isVisible(): boolean {
        if (this.element instanceof HTMLElement) {
            return (this.element.offsetParent !== undefined)
        }
    }

    /**
     * On events
     */

    public onHotKeys(keys: string[], func: (e: any) => void): void {
        keys.forEach(key => {
            this.hotKeyListeners.push(key);
            SedestralMachine.addHotKeyListener(this, key, func);
        })
    }

    public onHotKey(keys: string, func: (e: any) => void): string {
        this.hotKeyListeners.push(keys);
        SedestralMachine.addHotKeyListener(this, keys, func);
        return keys;
    }

    public onChange(func: (mutations?: MutationRecord[]) => void): () => void {
        if (this.mutationObserver == undefined) {
            this.mutationFunctions = [];
            this.mutationObserver = new MutationObserver((mutations) => {
                for (let f of this.mutationFunctions)
                    f(mutations);
            });
            this.mutationObserver.observe(this.getHTMLElement(), {
                subtree: true,
                childList: true,
                characterData: true,
                attributes: true,
                characterDataOldValue: true,
                attributeOldValue: true,
                attributeFilter: ['id', 'class'],
            });
        }

        this.mutationFunctions.push(func);

        return func;
    }

    public onResize(func: (rect: DOMRect | any) => void): void {
        if (this.element instanceof Element) {
            let f = () => {
                let offsets = this.getOffsets();
                func({height: this.getHeight(), width: this.getWidth(), x: offsets.left, y: offsets.top});
            };
            window.addEventListener("resize", f);
            this.onRemove(() => window.removeEventListener("resize", f));
            this.onChange(() => f());


            SedestralMachine.resizeObserver.observe(this.element);
            if (this.element['resizeFunctions'] == undefined) {
                this.element['resizeFunctions'] = [];
            }

            this.element['resizeFunctions'].push(func);
        }
    };

    public onInput(func: (e?: any) => void, delay?: number): SedestralMachine {
        return this.addListener('input', this.delay(func, (typeof delay == "undefined" ? 0 : delay)));
    };

    public onKey(func: (e?: any) => void, delay?: number): SedestralMachine {
        return this.addListener('keydown', this.delay(func, (typeof delay == "undefined" ? 0 : delay)));
    };

    public onKeyUp(func: (e?: any) => void, delay?: number): SedestralMachine {
        return this.addListener('keyup', this.delay(func, (typeof delay == "undefined" ? 0 : delay)));
    };

    public onClick(func: (e: any) => void, stopPropagation?: boolean, mobileDelay?: boolean): MachineListener {
        return this.addListener('click', (e) => {
            if (stopPropagation) {
                e.stopImmediatePropagation();
                e.stopPropagation();
            }

            if (isMobile() && mobileDelay) {
                this.timeOut(() => func(e), 200);
            } else {
                func(e);
            }
        });
    };

    public onDragEvent(onEnter: (e: any) => void, onLeave: (e: any) => void): MachineListener[] {
        let entered = false;
        let leave = true;
        let listeners = [];
        listeners.push(this.putListener(window, "dragleave", (e) => {
            if (!leave) {
                if (!elementParents(e.target).includes(this.getHTMLElement())) {
                    entered = false;
                    leave = true;
                    onLeave(e);
                }
            }
        }));
        listeners.push(this.putListener(window, "dragenter", (e) => {
            if (!entered) {
                if (e.target == this.getHTMLElement() || elementParents(e.target).includes(this.getHTMLElement())) {
                    this.timeOut(() => leave = false, 50);
                    entered = true;
                    onEnter(e);
                }
            }
        }));

        this.putListener(window, "focus", (e) => onLeave(e));

        return listeners;
    }

    public onDragDrop(func: (e: any) => void) {
        return this.addListener("drop", (e) => func(e));
    }

    public onRightClick(func: (e: any) => void) {
        this.addListener("contextmenu", (e) => {
            e.preventDefault();
            func(e);
        });
    }

    public onWindowResize(func: (e: any) => void) {
        this.putListener(window, "resize", func);
    }

    public onFocus(func: (e: any) => void): MachineListener {
        return this.addListener('focus', (e) => func(e));
    };

    public onBlur(func: (e: any) => void): MachineListener {
        return this.addListener('blur', (e) => func(e));
    };

    public onScroll(callback: () => void): MachineListener {
        return this.addListener("scroll", callback);
    }

    public async onOutsideClick(func: (e: any) => void, propagation?: boolean): Promise<MachineListener> {
        await SedestralMachine.sleep(50);
        let listener = this.putListener(window, 'click', (e) => {
            if (!elementNotChildren(document, e.target)) {
                if (!this.isNull() &&
                    elementNotChildren(this.element, e.target) &&
                    this.outsideClickExcludedComponents.filter(value => !elementNotChildren(value.element, e.target)).length == 0) {

                    if (typeof propagation != "undefined") {
                        e.stopImmediatePropagation();
                    }

                    func(e);
                }
            }
        });

        SedestralMachine.addOutsideClickListener(listener, this);
        return listener;
    }

    public async onOutsideRightClick(func: (e: any) => void, propagation?: boolean): Promise<SedestralMachine> {
        await SedestralMachine.sleep(50);
        return this.putListener(window, "contextmenu", (e) => {
            if (elementNotChildren(this.element, e.target)) {
                if (typeof propagation != "undefined") {
                    e.stopImmediatePropagation();
                }

                func(e);
            }
        })
    }

    public onComponentClick(name: string, func: () => void): SedestralMachine {
        let globalFunc = function (e) {
            let parentsClasses = [];
            let a = e.target;

            while (a) {
                a = a.parentNode;
                if (a != undefined) {
                    if (a.className !== "") {
                        parentsClasses.push(a.className)
                    }
                }
            }

            let has = false;
            if (elementHasClass(e.target.className, name)) {
                has = true;
            }

            for (let i = 0; i < parentsClasses.length; i++) {
                if (elementHasClass(parentsClasses[i], name)) {
                    has = true;
                }
            }


            if (has) {
                func();
            }
        };
        return this.addListener('click', globalFunc);
    };

    public onHover(func: (e: any) => void, time?: number): MachineListener {
        return this.addListener('mouseenter', (e) => {
            if (time == undefined) {
                func(e);
            } else {
                let timeOut = this.timeOut(() => {
                    func(e);
                }, time);
                let leaveListener = this.addListener('mouseleave', (e) => {
                    clearTimeout(timeOut);
                    this.deleteListener(leaveListener);
                });
            }
        });
    }

    public onLeave(func: (e: any) => void, time?: number): MachineListener {
        return this.addListener('mouseleave', (e) => {
            if (time == undefined) {
                func(e);
            } else {
                let timeOut = this.timeOut(() => {
                    func(e);
                }, time);
                let leaveListener = this.addListener('mouseenter', (e) => {
                    clearTimeout(timeOut);
                    this.deleteListener(leaveListener);
                });
            }
        });
    }

    public onSubmit(func: (e: any) => void): void {
        if (this.element instanceof HTMLElement) {
            this.element.onsubmit = (e) => {
                func(e);
            }
        }

    };

    public onCommit(func: () => void): void {
        this.onCommitFunctions.push(func);
    }

    public onRemove(func: () => void): void {
        this.onRemoveFunctions.push(func);
    }

    public onReactiveObserve(func: () => void): void {
        this.reactives.push(observe(() => func(), {lazy: false}));
    }

    public onBufferReactiveObserve(func: () => void): void {
        let scheduled;
        this.reactives.push(observe(() => func(), {
            lazy: false,
            scheduler: () => {
                clearTimeout(scheduled);
                scheduled = this.timeOut(() => {
                    if (!this.isNull()) {
                        func();
                    }
                }, 25);
                return setTimeout(undefined, 0);
            }
        }));
    }

    public onVisible(callback: (e) => void) {
        if (!this.isNull()) {
            new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0) {
                        callback(this.getHTMLElement());
                        observer.disconnect();
                    }
                });
            }).observe(this.getHTMLElement());
        }
    }

    public onParentsScroll(callback: (e) => void): MachineListener {
        return this.putListener(document, "scroll", (e) => {
            if (elementParents(this.getHTMLElement()).includes(e.target)) {
                callback(e);
            }
        }, true)
    }

    /**
     * focus
     */

    public isFocus(): boolean {
        return (document.activeElement == this.element);
    }

    public focus(): void {
        if (this.element instanceof HTMLElement) {
            this.element.focus();
        }
    }

    public focusAtEnd(): void {
        this.focus();

        let inputElement = this.getHTMLElement() as HTMLInputElement;
        if (inputElement) {
            let strLength = this.getValue().length * 2;
            SedestralMachine.requestFrame()(() => {
                let type = inputElement.type;
                inputElement.type = "text";
                inputElement.focus();
                inputElement.setSelectionRange(strLength, strLength);
                inputElement.type = type;
            });
        }
    }

    /**
     * append and prepend html text inside component
     */

    public pendTop(): void {
        let eElement = this.element.parentNode;
        if (eElement.firstChild != this.element) {
            let newFirstElement = this.element;
            eElement.insertBefore(newFirstElement, eElement.firstChild);
        }
    }

    public pendBottom(): void {
        let eElement = this.element.parentNode;
        let newFirstElement = this.element;

        eElement.appendChild(newFirstElement);
    }

    public pendAfter(beforeComponent: Component, component?: Component,) {
        component = component ? component : this;

        beforeComponent.element.parentElement.appendChild(component.element);
        let beforeElement = beforeComponent.element;
        beforeElement = beforeElement.nextSibling == undefined ? beforeElement : beforeElement.nextSibling;

        beforeComponent.element.parentElement.insertBefore(component.element, beforeElement);
    }

    public pendBefore(beforeComponent: Component, component?: Component) {
        component = component ? component : this;
        beforeComponent.element.parentElement.appendChild(component.element);
        beforeComponent.element.parentElement.insertBefore(component.element, beforeComponent.element);
    }

    public prepend(html: string, tag?: string): Component {
        let component = this.append(html, tag);
        component.pendTop();
        return component;
    };

    public append(html: string, tag?: string): Component {
        let buffer = document.createElement(typeof tag == "undefined" ? "div" : typeof this.tagParents[tag] == "undefined" ? tag : this.tagParents[tag]);
        buffer.innerHTML = html.trim();
        let element = buffer.firstChild;

        if (buffer.childNodes.length > 1) {
            this.element?.appendChild(buffer);
            this.element?.insertBefore(buffer, this.element.lastChild);
            element = buffer;
        } else {
            this.element?.appendChild(element);
            this.element?.insertBefore(element, this.element.lastChild);
        }

        let component = SedestralInterface.el(element);
        this.childrens.filter(value => !value.drawCommitted).forEach(value => this.drawCommit(value));

        if (!component.committed) {
            component.tryCommit();
        }

        this.addChildren(component);
        return component;
    };

    /**
     * Reactive
     */

    public reactive(v: () => string): string {
        let id = randomString(18, true);
        this.onReactiveObserve(() => {
            try {
                let value = v();
                if (!this.isNull()) {
                    let element = this.getHTMLElement().getElementsByClassName(id)[0];
                    if (element != undefined) {
                        element.innerHTML = filterXSS(value);
                    }
                }
            } catch (e) {

            }
        });

        return `<reactive class="${id}">${filterXSS(v())}</reactive>`;
    }

    public reactiveObservable<Observable extends object>(obj?: Observable): Observable {
        return observable(obj);
    }

    public reactiveUnObserve(v: () => void) {
        unobserve(v);
    }

    public reactivesUnObserve(): void {
        for (let i = 0; i < this.reactives.length; i++) {
            this.reactiveUnObserve(this.reactives[i]);
        }

        this.reactives = [];
    }

    /**
     * Return the template of an component and added to childrens for final commit
     */

    public drawAll(components: Component[]): string {
        let template = "";
        for (let i = 0; i < components.length; i++) {
            let component = components[i];
            template += this.draw(component);
        }

        return template;
    }

    public drawCommit(component: Component): void {
        component.drawCommitted = true;
        if (!component.committed) {
            let element = document.getElementById(component.componentId);
            if (element != undefined) {
                component.element = element;
                component.removeAttribute("id");
            }

            component.tryCommit();
        }
    }

    public draw(component: Component): string {
        component.drawCommitted = false;

        let buffer = document.createElement('div');
        buffer.innerHTML = component.getTemplate().trim();

        if (buffer.childNodes.length > 1) {
            buffer.id = component.componentId;
            component.template = buffer['outerHTML'];
        } else {
            buffer.firstChild['id'] = component.componentId;
            component.template = buffer['innerHTML'];
        }

        if (this.committed) {
            SedestralMachine.requestFrame()(() => this.drawCommit(component));
        } else {
            this.onCommit(() => this.drawCommit(component));
        }

        this.addChildren(component);
        return component.getTemplate();
    }

    public drawFly(component: Component) {
        if (this.committed) {
            this.render(component);
            let html = component.getHTMLElement().outerHTML;
            component.remove();

            return html;
        }
    }

    /**
     * Append a component inside other, better performance than draw()
     */

    public prerender(component: Component): void {
        this.render(component, true);
    }

    public render(component: Component, prepend?: boolean, beforeComponent?: Component, beforeAfter?: boolean): Component {
        let buffer = document.createElement(typeof this.tagParents[component.type] == "undefined" ? component.type : this.tagParents[component.type]);
        buffer.innerHTML = component.getTemplate().trim();
        let element = buffer.firstChild;

        if (buffer.childNodes.length > 1) {
            element = buffer;
        }

        this.element?.appendChild(element);
        this.element?.insertBefore(element, !prepend ? this.element.lastChild : this.element.firstChild);

        component.element = element;
        if (!component.committed) {
            component.tryCommit();
        }

        this.addChildren(component);

        if (beforeComponent) {
            beforeAfter ? this.pendAfter(beforeComponent, component) : this.pendBefore(beforeComponent, component);
        }

        return component;
    }

    /**
     * call this function after apend in DOM
     */
    public tryCommit(): void {
        try {
            this.commit();
            this.committed = true;
        } catch (e) {
            SedestralDebugger.addError("SEDESTRAL COMMIT ERROR", e);
            this.committedError = true;
            this.commitFailed();
        }
    }

    public commit(): void {
        if (this.committed) {
            /*
            console.log("error: already commited")
            console.log(this)
            console.log(this.onCommitFunctions)
             */
        }

        if (!this.committed) {
            this.committed = true;
            for (let commitFunc of this.onCommitFunctions)
                commitFunc();
        }

    }

    public commitFailed(): void {

    }

    public commitTooltips(): void {
        let elements = elementChildrens(this.getHTMLElement());
        elements.forEach(element => {
            if (!element['commitTooltips']) {
                element['commitTooltips'] = true;
                let tooltipText = element.getAttribute("data-tooltip");
                let tooltipPosition = element.getAttribute("data-tooltip-position");
                if (tooltipText != undefined) {
                    let component = this.ele(element);
                    component.setTooltip(tooltipText, tooltipPosition);
                }
            }
        });
    }


    /**
     * errors
     */
    public addNetworkErrors(errors: INetworkComponentError[]): void {
        errors.forEach(error => this.networkErrorsHandler.push(error));
    }

    /**
     * create an empty element in DOM with className
     */
    public virtual(name: string): void {
        let buffer = document.createElement('div');
        buffer.className = name;
        this.element = buffer;
        document.body.appendChild(buffer);
        document.body.insertBefore(buffer, this.element.lastChild);
    }

    public addChildren(element: Component): void {
        if (!this.childrens.includes(element)) {
            this.childrens.push(element);
        }
    }

    public removeChildren(element: Component): void {
        let index = this.childrens.indexOf(element);
        if (index >= 0) {
            this.childrens.splice(index, 1);
        }
    }

    /**
     * save and restore headers
     */

    public saveScroll(): void {
        this.savedScroll = this.getHTMLElement().scrollTop;
        this.savedScrollCalc = this.getHTMLElement().scrollHeight - this.getHTMLElement().clientHeight;
    }

    public restoreScroll() {
        this.getHTMLElement().scrollTop = this.savedScroll + ((this.getHTMLElement().scrollHeight - this.getHTMLElement().clientHeight) - this.savedScrollCalc);
    }

    public save(): void {
        if (this.element instanceof HTMLElement) {
            this.savedStyle = this.element.getAttribute("style");
            this.savedHtml = this.element.innerHTML;
        }
    };

    public saveAndClear(): void {
        this.save();
        this.clear();
    }

    public saveForLoading(): void {
        this.setStyle(`padding:0;height:${this.getHeightWithoutBorders()}px;width:${this.getWidth()}px;`);
        this.saveAndClear();
    }

    public restore(): void {
        if (this.element instanceof HTMLElement) {
            this.element.setAttribute("style", this.savedStyle);
            this.element.innerHTML = this.savedHtml;
        }
    };

    /**
     * Listeners
     */

    public clearListeners(): void {
        for (let i = 0; i < this.listeners.length; i++) {
            SedestralMachine.removeListener(this.listeners[i]);
        }

        this.hotKeyListeners.forEach(value => SedestralMachine.deleteHotKeyListener(this, value));

        this.listeners = [];
        this.hotKeyListeners = [];
    };

    public clearTooltipListeners(): void {
        for (let i = 0; i < this.tooltipListeners.length; i++)
            SedestralMachine.removeListener(this.tooltipListeners[i]);

        this.tooltipListeners = [];

        if (this.tooltip != undefined && this.tooltip.tooltipComponent != undefined && !this.tooltip.tooltipComponent.isNull()) {
            this.tooltip.tooltipComponent.remove();
        }

        this.tooltip = undefined;
    };

    public clearTimeouts(): void {
        for (let i = 0; i < this.timeOuts.length; i++) {
            clearTimeout(this.timeOuts[i]);
        }
        this.timeOuts = [];
    }

    public clearIntervals(): void {
        for (let i = 0; i < this.intervals.length; i++) {
            clearInterval(this.intervals[i]);
        }
        this.intervals = [];
    }

    public addListener(name: string, func: any): MachineListener {
        SedestralDebugger.eventTarget(this, name);
        let rum = SedestralDebugger.rum?.eventTarget(name);
        return this.putListener(this.element, name, (e) => {
            func(e);
            rum?.end();
        });
    }

    public putListener(element: EventTarget, name: string, func: any, options?: boolean): MachineListener {
        let listener = SedestralMachine.addListener(element, name, func, options);
        this.listeners.push(listener);
        return listener;
    }

    public deleteListener(data: MachineListener) {
        if (data != undefined) {
            SedestralMachine.removeListener(data);
            let index = this.listeners.indexOf(data);
            if (index >= 0) {
                this.listeners.splice(index, 1);
            }
        }
    }

    public deleteListeners(listeners: MachineListener[]) {
        for (let listener of listeners) {
            this.deleteListener(listener);
        }
    }

    public deleteHotKeyListener(hotkeys: string) {
        SedestralMachine.deleteHotKeyListener(this, hotkeys);
        let index = this.hotKeyListeners.indexOf(hotkeys);
        if (index >= 0) {
            this.hotKeyListeners.splice(index, 1);
        }
    }

    public deleteHotKeyListeners(hotkeys: string[]) {
        hotkeys.forEach(value => this.deleteHotKeyListener(value));
    }

    public deleteOnChangeListener(func) {
        this.mutationFunctions.splice(this.mutationFunctions.indexOf(func), 1);
    }

    /**
     * run listeners
     */

    public runRemoves(): void {
        if (this.onRemoveFunctions.length > 0) {
            for (let removeFunc of this.onRemoveFunctions)
                removeFunc();
        }
        this.onRemoveFunctions = [];
    }

    /**
     * Class interaction
     */
    public hasClass(cls: string): boolean {
        if (this.element instanceof HTMLElement) {
            return elementHasClass(this.element.className, cls);
        }
    };

    public addClass(cls: string): void {
        if (this.element instanceof HTMLElement) {
            if (!this.hasClass(cls)) {
                const last = this.element.className.charAt(this.element.className.length - 1);
                this.element.className += ((last == " " ? "" : " ") + cls);
            }
        }

    };

    public removeClass(cls: string): void {
        if (this.element instanceof HTMLElement) {
            if (this.hasClass(cls)) {
                let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
                this.element.className = this.element.className.replace(reg, " ");
            }
        }
    };

    /**
     * Attribute interactions
     */

    public getAttribute(name: string): string {
        if (this.element instanceof HTMLElement) {
            return this.element.getAttribute(name);
        }

    }

    public setAttribute(name: string, content?: string): void {
        if (this.element instanceof HTMLElement) {
            this.element.setAttribute(name, content);
        }

    };

    public removeAttribute(name: string): void {
        if (this.element instanceof HTMLElement) {
            this.element.removeAttribute(name);
        }
    };

    /**
     * child headers manipulation
     */
    public els(className: string): Component[] {
        if (this.element instanceof HTMLElement) {
            let elements = [];
            let components = [];
            if (this.hasClass(className)) {
                elements.push(this.element);
            }
            let classes = this.element.getElementsByClassName(className);
            for (let i = 0; i < classes.length; i++) {
                elements.push(classes[i]);
            }

            for (let element of elements) {
                let cache = this.childrens.filter(value => value.element == element)[0];
                if (cache == undefined) {
                    let e = SedestralInterface.el(element);
                    e.committed = true;

                    components.push(e);
                    this.addChildren(e);
                } else {
                    components.push(cache);
                }

            }
            return components;
        }
    }

    public el(className: string, noCache?: boolean): Component {
        if (!noCache) {
            let cache = this.childrens.filter(value => value.element != undefined && value.hasClass(className))[0];
            if (cache != undefined) {
                return cache;
            }
        }

        if (this.element instanceof HTMLElement) {
            let e = SedestralInterface.el(this.hasClass(className) ? this.element : this.element.getElementsByClassName(className)[0]);
            e.committed = true;

            this.addChildren(e);
            return e;
        }
    }

    public ele(element: HTMLElement): Component {
        let cache = this.childrens.filter(value => value.element == element)[0];
        if (cache == undefined) {
            let e = SedestralInterface.el(element);
            e.committed = true;

            this.addChildren(e);
            return e;
        }

        return cache;
    }

    public has(className: string): boolean {
        if (this.element instanceof HTMLElement) {
            let elements = this.element.getElementsByClassName(className);
            if (elements.length > 0) {
                return true;
            }
        }
        return false;
    }

    public clearChildrens(): void {
        for (let i = 0; i < this.childrens.length; i++) {
            let child = this.childrens[i];
            child.destroy();
        }
        this.childrens = [];
    }

    public count(className: string): number {
        if (this.element instanceof HTMLElement) {
            let elements = this.element.getElementsByTagName("*");
            let count = 0;
            for (let i = 0; i < elements.length; i++) {
                if (!!elements[i].className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))) {
                    count++;
                }
            }
            return count;
        }
    }

    /**
     * apply events
     */

    public delay(callback: (e) => void, ms: number): (params) => void {
        if (ms == 0) {
            return callback;
        } else {
            let timer = 0;
            return (params) => {
                clearTimeout(timer);
                timer = SedestralMachine.setTimeout(() => {
                    callback(params);
                }, ms || 0);
            };
        }
    };

    public timeOut(callback: () => void, ms: number): number {
        let timeout = SedestralMachine.setTimeout(() => {
            callback();
        }, ms);
        this.timeOuts.push(timeout);
        return timeout;

    }

    public interval(callback: () => void, ms: number, lazy?: boolean): number {
        if (!lazy) {
            callback();
        }
        let timeout = SedestralMachine.setInterval(() => {
            callback();
        }, ms);
        this.intervals.push(timeout);
        return timeout;
    }

    /**
     * others
     */
    public isNull(): boolean {
        return this.element == undefined;
    }


    /**
     * end
     */
    public remove(): void {
        if (this.element != undefined && this.element.parentNode !== undefined) {
            if (this.element.parentNode) {
                this.element.parentNode.removeChild(this.element);
            }
        }

        this.destroy();
    };

    public nullify(): void {
        if (this.getHTMLElement() != undefined) {
            SedestralMachine.resizeObserver.unobserve(this.getHTMLElement());
        }

        if (this.mutationObserver != undefined) {
            this.mutationObserver.disconnect();
            this.mutationObserver = undefined;
            this.mutationFunctions = [];
        }


        this.clearAll();

        this.element = undefined;

        this.timeOuts = [];
        this.intervals = [];

        this.reactives = [];
        this.listeners = [];
        this.hotKeyListeners = [];
        this.childrens = [];
        this.isHide = undefined;

        this.savedHtml = undefined;
        this.savedStyle = undefined;
        this.savedScroll = undefined;
        this.savedScroll = undefined;
        this.savedScrollCalc = undefined;

        this.outsideClickExcludedComponents = [];

        this.template = undefined;
        this.type = undefined;

        this.scrollbar = undefined;
        this.swapBar = undefined;


        this.runRemoves();

        this.onCommitFunctions = [];

        for (let key in this) {
            if (this.hasOwnProperty(key)) {
                let value = this[key];
                let isArray = Array.isArray(value);
                if ((isArray && value['length'] != 0) || (!isArray && value != undefined)) {
                    if (isArray) { // @ts-ignore
                        value = [];
                    } else {
                        value = undefined;
                    }
                }
            }
        }
    }

    public destroy(): void {
        this.nullify();
        SedestralInterface.unStore(this);
    }

    public destroyChildrens() {
        this.childrens.forEach(value => {
            value.destroyChildrens();
            value.destroy();
        });
    }

    public clear(): void {
        if (this.element instanceof HTMLElement) {
            this.element.innerHTML = "";
            this.clearChildrens();
        }
    };

    public clearAll(): void {
        this.scrollbar = undefined;
        this.swapBar = undefined;
        this.clear();
        this.clearListeners();
        this.clearTooltipListeners();
        this.clearIntervals();
        this.clearTimeouts();
        this.reactivesUnObserve();
    }

    /**
     * change element status
     */

    public scrollable(autoPlace?: boolean): ComponentScrollbar {
        if (this.scrollbar == undefined) {
            this.scrollbar = new ComponentScrollbar(this, autoPlace);
        }

        return this.scrollbar;
    }

    public swapable(onDispose?: () => void): ComponentSwapBar {
        if (isMobile()) {
            if (this.swapBar == undefined) {
                this.swapBar = new ComponentSwapBar(this, onDispose);
            }

            return this.swapBar;
        }
    }

    public unClickable(): void {
        this.onClick((e) => {
            e.preventDefault();
        }, true);
    }

    /**
     * tooltip
     */

    public showShadowTooltip(template: any, color?: string) {
        this.setStyle(`box-shadow: 0 0 0 2px ${color};`);
        this.showTooltip(template, 2000, "bottom");
        clearTimeout(this.tooltipShowShadowTimeout);
        this.tooltipShowShadowTimeout = this.timeOut(() => {
            this.setStyle(`box-shadow: unset;`);
        }, 2000);
    }

    public showTooltip(template: any, delay?: number, position?: string, noAnimation?: boolean) {
        clearTimeout(this.tooltipShowTimeout);
        if (!this.tooltip) {
            this.tooltip = new ComponentTooltip(this, template, position, noAnimation);
        }

        this.tooltipShowTimeout = this.timeOut(() => {
            if (this.tooltip) {
                this.tooltip.tooltipComponent.remove();
            }
            this.tooltip = undefined;
        }, delay ? delay : 3000);
    }

    public setTooltip(template: any, position?: string, noAnimation?: boolean, disabled?: () => boolean) {
        if (isMobile()) {
            let showed = false;
            let show = () => {
                if (disabled == undefined || !disabled()) {
                    this.tooltip = new ComponentTooltip(this, template, position, noAnimation);
                    showed = true;
                }
            };
            let hide = () => {
                if (this.tooltip != undefined && this.tooltip.tooltipComponent != undefined && !this.tooltip.tooltipComponent.isNull()) {
                    this.tooltip.tooltipComponent.remove();
                    this.tooltip = undefined;
                    showed = false;
                }
            };

            this.tooltipListeners.push(this.onClick(() => {
                if (showed)
                    hide();
                else
                    show();
            }));
            this.onOutsideClick(() => hide())
                .then(value => this.tooltipListeners.push(value))
        } else {
            this.tooltipListeners.push(this.onHover(() => {
                if (disabled == undefined || !disabled()) {
                    this.tooltip = new ComponentTooltip(this, template, position, noAnimation);
                }
            }));
            this.tooltipListeners.push(this.onClick(() => {
                if (this.tooltip != undefined && this.tooltip.tooltipComponent != undefined && !this.tooltip.tooltipComponent.isNull()) {
                    this.tooltip.tooltipComponent.remove();
                    this.tooltip = undefined;
                }
            }));
        }
    }


    /**
     * getter and setter
     */

    public getHTMLElement(): HTMLElement {
        if (this.element instanceof HTMLElement) {
            return this.element;
        }
    }

    public getTemplate(): string {
        return this.template;
    }

}